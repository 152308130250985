import React, { useRef } from "react";
import { FileUpload } from 'primereact/fileupload';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import { Link } from 'react-router-dom';

const DoFileUpload = (props) => {
    const fileInputRef = useRef(null);

    const id = props.id ? props.id : props.name;
    let label = props.label ? props.label : '';
    let newFiles = (props.field.value) ? [props.field.value ]: ''
    let isMultiple = props.isMultiple ? props.isMultiple : false;
    let modeType = props?.modeType ? props.modeType : 'advanced'
    let type = props.type;
    let maxFileSize = props.maxFileSize

    const onSelect = (e) => {
        const selectedFiles = isMultiple ? e.files : e.files[0];
        const oversizedFiles = [e.files[0]].filter(file => file.size > maxFileSize);

        if (oversizedFiles.length > 0) {
            const bytesPerMB = 1024 * 1024;
            showToasterMessage(`File size should be less than ${maxFileSize / bytesPerMB} MB`, 'error');
            clearFileUpload();
        } else {
            uploadFilesToServer(selectedFiles);
        }
    }

    const upload = (e) => {
        uploadFilesToServer(isMultiple ? e.files : e.files[0]);
    }

    const setProgressBarResponse = (initialFiles, status) => {
        if(props.updateFileStatus){
            initialFiles.forEach((file, index) => props.updateFileStatus(index, 100, status));
        }
    }

    const uploadFilesToServer = (data) => {
        const initialFiles =data && data.map ? data.map(file => ({
            name: file.name,
            progress: 0,
            status: 'uploading'
        })) : [];
        if(props.showCurrentFilesInProgressBar){
            props.showCurrentFilesInProgressBar(true)
            if(props.setFilesToProgressBar){
                props.setFilesToProgressBar(initialFiles)
            }
            if(props.updateFileProgress){
                props.updateFileProgress({ totalFiles: initialFiles.length, uploadedFiles: 0 })
            }
        }
        if(modeType === 'basic'){
            clearFileUpload()
        }
        fetchMethodRequest('POST', props.url, data, 'upload', isMultiple,props?.assingValueToForm)
            .then(async (response) => {
                if (props.type === 'profile') {
                    let data = JSON.parse(localStorage.getItem('loginCredentials'))
                    data.photo = response.fileName;
                    localStorage.setItem('loginCredentials', JSON.stringify(data))
                    props.field.onChange(response.fileName)
                    clearFileUpload();
                    newFiles=[response.fileName]
                }       
                if(props?.onSuccess) {
                    props.onSuccess(response);
                }             
                if (response) {
                    props.closeFormModal();
                    showToasterMessage(response.respMessage, 'success');
                    setProgressBarResponse(initialFiles,'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    setProgressBarResponse(initialFiles,'error');
                } else {
                    showToasterMessage('upload failed')
                    setProgressBarResponse(initialFiles,'error');
                }
            }).catch((err) => {
                setProgressBarResponse(initialFiles,'error');
                return err;
            });
    }

    const checkImg = (img) => {
        if (img) {
            return config.imageFormats.some(x => img.toLowerCase().includes(x));
        }
        return false;
    };

    const deleteFile = (img) => {
        if (img) {
            let allFiles = newFiles.filter(file => file !== img)
            if (allFiles && allFiles.length === 0){
                props.field.onChange('')
            }else {
                props.field.onChange(allFiles)

            }
        }
    };

    const clearFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.clear()
        }
    };


    return (
        <div className='d-flex flex-column'>
            { !props.hideLabel && 
                <div className="flex align-center">
                    <label htmlFor={id} className="text-capitalize">{label}</label>
                </div>
            }
            <FileUpload
                ref={fileInputRef}
                mode={modeType}
                name={props.name ? props.name : 'file'}
                // accept={props.acceptType ? props.acceptType : '*'}
                auto={props.autoSubmit}
                accept={props.acceptType ? props.acceptType : '.pdf,.docx'}
                onSelect={!isMultiple ? onSelect : ''}
                emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                customUpload
                uploadHandler={upload}
                multiple={isMultiple ? isMultiple : false}
                chooseLabel={props.label ? props.label : 'Choose File'}
                chooseOptions={props?.chooseOptions ? props?.chooseOptions : {className : ''}} 
            />
            <div>
                {newFiles && newFiles.length > 0 && (
                    <div className="d-flex flex-wrap">
                        {newFiles.map(img => (
                            <div className="row w-50" >
                                <div className="col-7 my-2">
                                {props.type === 'profile' ?
                                        (<Link to={`${config.profileUrl}${img}`} target="_blank">
                                            <img src={config.profileUrl + img} style={{ width: '100%', height: '100%' }} alt="" />
                                        </Link>) :
                                        checkImg(img) ? (
                                            <Link to={`${config.imgUrl}${type.toLowerCase()}/${img}`} target="_blank">
                                                <img src={config.imgUrl + `${type.toLowerCase()}/` + img} style={{ width: '80%' }} alt="" />
                                            </Link>
                                        ) : (
                                            <a href={`${config.imgUrl}${type.toLowerCase()}/${img}`} target='_blank' >
                                                <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                                            </a>
                                        )}
                                </div>
                                <div className="col-4 my-3">
                                    <button className="btn text-danger" type='button' onClick={() => deleteFile(img)} style={{ padding: '5px 10px' }}>
                                        <i className="pi pi-trash" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
export default DoFileUpload;