const imagePath = '../assets/';
const configImages = {
  csv: require(`../assets/img/logo/csv.png`),
  xlsx: require(`../assets/img/logo/xlsx.png`),
  xls: require(`../assets/img/logo/xlsx.png`),
  ppt: require(`../assets/img/logo/ppt.jpg`),
  pdf: require(`../assets/img/logo/pdf.jpg`),
  json: require(`../assets/img/logo/json.jpg`),
  txt: require(`../assets/img/logo/txt.jpg`),
  docx: require(`../assets/img/logo/docx.jpg`),
  doc: require(`../assets/img/logo/docx.jpg`),
  defaultImg: require(`../assets/img/profile/defaultImg.png`),
  defaultUserImage: require(`../assets/img/profile/default_user_image.png`),
  employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
  loginImage: require(`../assets/img/logo/default_project_logo.0fd4ce62141feb00d469.png`),
  loginSideImage: require(`../assets/img/logo/default_LoginSideImage.webp`),
  // loginLogo: require(`../assets/img/logo/loginLogo.jpg`),
  loaderImage: require(`../assets/loader.gif`),
  dosystemsImg : require('../assets/img/logo/dosystems.jpg'),
  dosystemsWhiteLogo : require('../assets/img/logo/white_logo_image.png')

};
export default configImages;
