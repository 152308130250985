import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { stripHtmlTags } from '../../../utils/utils';
import { marked } from 'marked';

const CopyText = ({text , iconName , copyType}) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopyClick = (text) => {
        if(copyType == 'TextCopy') {
            text = stripHtmlTags(marked(text));
        }
        navigator.clipboard.writeText(text).then(() => {
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false)
            }, 1500);
        }, () => {
            setCopySuccess(false);
        });
    };

    return (
        <span>
            { copySuccess ?

                <FontAwesomeIcon
                icon='check'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="Message Copied"
                style={{
                    color :'#2A3650'
                  }}
                />
             :
                <FontAwesomeIcon
                icon={iconName ? iconName : 'clone'}
                className='pl-1' size='lg'
                data-toggle="tool-tip" title={copyType == 'TextCopy' ? 'Copy As Text' : 'Copy Message'}
                style={{
                    color :'#2A3650'
                  }}
                onClick={() =>handleCopyClick(text)}
                />
            }
        </span>
    );
};

export default CopyText;
