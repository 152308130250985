import React, { forwardRef, useEffect, useImperativeHandle, useState ,useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchMethodRequest from '../../../config/service';
import { NavLink, useNavigate } from 'react-router-dom';
import { faShareAlt} from '@fortawesome/free-solid-svg-icons';

const MenuBar = forwardRef(({ setMessages, chatId , searchType ,defaultSearchFilter , type , shareChats},ref) => {
  const [isOpen, setIsOpen] = useState(true);
  const [chats ,setChats] = useState([])
  const navigate = useNavigate();
  let filterRef = useRef({"page":1,"limit":20,"sortfield":"created","direction" : "desc"})
  let chatsRef = useRef([])
  const [totalCount,setTotalCount] = useState(0)
  let previousScrollHeightRef = useRef()
  let scrollableRef = useRef();
  const isThrottled = useRef(false);


  useImperativeHandle(ref, () => ({
    createChat(newChat, details) {
      setChats([])
      chatsRef.current = [];
      filterRef.current = {"page":1,"limit":20,"sortfield":"created","direction" : "desc"};
      getChats(newChat,null, details)
    },
    getSharedChats() {
      setChats([])
      chatsRef.current = [];
      filterRef.current = {"page":1,"limit":20,"sortfield":"created","direction" : "desc"};
      handleSharedChats();
    }
  }));

  const getChats = (newChat = false , defaultFilter = null, details) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(defaultSearchFilter) {
        finalFilter.criteria = [defaultSearchFilter]
    }
    fetchMethodRequest('GET',`chats?filter=${JSON.stringify(finalFilter)}`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
          if(newChat){
            if(details?.messages?.chatId) {
              navigate(`/${searchType}/${details.messages.chatId}` , {state : { chat : {...details?.messages, conversationType : details?.conversationType}}})
            } else {
              navigate(`/${searchType}/${resp.chats[0]?._id}`)
            }
          }
        }
      }
    })
  }

  const handleSharedChats = (defaultFilter) => {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    fetchMethodRequest('GET',`chats/shareChat`).then((resp) => {
      if(resp.chats && resp.chats?.length > 0){
        if(resp?.pagination?.page) {
          let obj = Object.assign(finalFilter, {"page" : resp?.pagination?.page})
          finalFilter.page = resp?.pagination?.page
          filterRef.current = obj
        }
        if(resp?.pagination?.totalCount){
          setTotalCount(resp?.pagination?.totalCount)
        }
        if (resp && resp.chats) {
          if(finalFilter?.page === 1) {
            setChats(resp.chats);
            chatsRef.current = resp.chats
          }
          else {
            chatsRef.current = [...chatsRef.current,...resp.chats];
            previousScrollHeightRef.current = scrollableRef.current.scrollHeight
            setChats(chatsRef.current);
            scrollToCurrentHeight()
          }
        }
      }
    })
  }

  useEffect(() => {
    if(type === 'shared') {
        handleSharedChats();
    } else {
      getChats()
    }
  },[])

  const navigateToNewChat = () => {
    setMessages([])
  }

  const scrollToCurrentHeight = () => {
    if(scrollableRef.current) {
      scrollableRef.current.scrollTop =  previousScrollHeightRef.current - scrollableRef.current.scrollHeight ;
    }
  }

  const onScrollTop = (e) => {
    if (isThrottled.current) {
      return;
    }
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    if(bottom && (chats?.length < totalCount)) {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      if(type) {
        handleSharedChats(currentFilter)
      } else {
        getChats(false,currentFilter);
      }  
      isThrottled.current = true;
      setTimeout(() => {
        isThrottled.current = false;
      }, 300);
    }
  }
  return (
    <div className='col-sm-3 d-flex flex-column'>
    <div className='d-flex justify-content-between menu-buttons mt-1'>
            <NavLink to={'/'+searchType} onClick={navigateToNewChat}>
                <button className={!type ? 'new-chat-button active-button' : 'new-chat-button' } title='New Chat'>
                  <FontAwesomeIcon icon="plus" title='New Chat' />
                  <span className='ml-2'>New Chat</span>
                </button> 
            </NavLink>
            { shareChats && <NavLink to={'/'+searchType+'?type=shared'}>
                <button className={type ? 'new-chat-button active-button' : 'new-chat-button' } title='Shared Chats'>
                  <FontAwesomeIcon icon={faShareAlt} title='Shared Chats' />
                  <span className='ml-2'>Shared Chats</span>
                </button> 
            </NavLink>}
       </div>
    <div className="menu-bar chat-menubar" style={{ display: isOpen ? 'block' : 'none' }} onScroll={onScrollTop}>
      <ul className="message-list" ref={scrollableRef}>
        {
          chats && chats.length > 0 && chats.map((chat, index) => (
            <NavLink to={type ? `/${searchType}/${chat._id}?type=${type}` : `/${searchType}/${chat._id}`} state={{chat}}>
              <li key={index}  className={chatId === chat._id ? 'active-chat' : ''}>
                  <div className='d-flex justify-content-between'>
                  <div className='chat-name' title={chat.question}>{chat.question}</div> 
                  <div className='active-chat-options'> <FontAwesomeIcon icon="ellipsis-h" /></div>
                  </div>
              </li>
            </NavLink>
          ))
        }
      </ul>
    </div>
    </div>
  );
});

MenuBar.propTypes = {
  messages: PropTypes.array.isRequired,
  onSelectMessage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onNewChat: PropTypes.func.isRequired,
};

export default MenuBar;
