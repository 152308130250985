import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configImages from '../../../../config/configImages';
import MenuBar from '../../../Layout/sidebar/menubar';
import fetchMethodRequest from '../../../../config/service';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../../config/configMessages';
import { TypingLoader } from './typingLoader';
import {  useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import ReadAloud from '../../CommonComponents/ReadAloud';
import CopyText from '../../CommonComponents/CopyText';
import {marked} from 'marked';
import apiCalls from '../../../../config/apiCalls';

const Search = () => {
  const [messages, setMessages] = useState([]);
  let messagesRef = useRef([])
  const [input, setInput] = useState('');
  const chatHistoryRef = useRef(null);
  const menuRef = useRef(null);
  const noProfileImage = configImages.defaultImg;
  const {chatId} = useParams();
  let filterRef = useRef({"page":1,"limit":20,"sortfield":"created","direction" : "desc","criteria":[{"key":"chatId","value": chatId,"type":"eq"}]})
  const [totalCount,setTotalCount] = useState(0)
  let newMessageRef = useRef();
  let previousScrollHeightRef = useRef();
  const [disableButton , setDisableButton] = useState(false);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  let chatIdRef = useRef(chatId);
  let inputRef = useRef(null);
  let promptKeyRef = useRef('');
  const [activeSpeechMessageId ,  setActiveSpeechMessageId] = useState('')


  useEffect(() => {
    let obj = Object.assign(filterRef.current,{"page" : 1,"criteria":[{"key":"chatId","value": chatId,"type":"eq"}]});
    filterRef.current = obj;
    chatIdRef.current = chatId;
    setMessages([]);
    setIsInitialLoaded(false)
    messagesRef.current = [];
    setTimeout(() => {
      insertMessages(obj)
    }, 200);
  },[chatId])

  useEffect(() => {
    if (chatHistoryRef.current && (filterRef.current?.page === 1 || newMessageRef.current)) {
      newMessageRef.current = false
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    } else {
      if(chatHistoryRef?.current){
        chatHistoryRef.current.scrollTop = chatHistoryRef?.current.scrollHeight - previousScrollHeightRef.current;
      }

    }
  }, [messages]);

  const createChat = () => {
    if(menuRef?.current) {
      menuRef.current?.createChat(true)
    }
  }

  const updateLastMessage = (res={ messages : { question: input, answer: `
        <div class="alert alert-danger text-center" role="alert">
            ${configMessages.deafultErrorMessage}
        </div>` , 'type' : 'error'} }) => {
    let updatedMessages = messages
    if(messages[messages.length - 1]?.answer === 'loader') {
      updatedMessages = messages.slice(0,-1);
      updatedMessages.push(res.messages)
    }
    else {
      updatedMessages.push(res.messages)
    }
    setMessages([...updatedMessages]);        
  }

  const handleSend = (event ,query, type) => {
    if(!type) {
      if(!input || disableButton) {
        return;
      }
    }
    if( type == 'regenerate') {
      if(!query) {
        return
      }
    }
    let url;
    if(chatId){
      url = `${apiCalls.fileRead}?chatId=${chatId}`
    } else {
      url = apiCalls.fileRead
    }
    setDisableButton(true);
    let body = { "query": query ? query?.trim() :input?.trim() , queryType : promptKeyRef.current}
    const userMessage = { question: query ? query : input, answer: 'loader' };
    setInput('');
    setMessages([...messages, userMessage]);
    fetchMethodRequest('POST', url,  body )
      .then((res) => {
        if( chatIdRef.current && res?.messages?.chatId && res?.messages?.chatId !== chatIdRef.current){
          setDisableButton(false);
          return
        }
        if (res && res.messages) {
          newMessageRef.current = true;
          updateLastMessage(res)
          if(!chatIdRef.current){
            createChat()
          }
        }else{
          updateLastMessage()
        }
        setDisableButton(false)
      }).catch((err) => {
        console.error("Fetch error:", err);
        updateLastMessage()
        showToasterMessage(configMessages.deafultErrorMessage, 'error');
      });
  };

  function insertMessages(defaultFilter) {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(!chatId) return;
    fetchMethodRequest('GET',`${apiCalls.messages}?filter=${JSON.stringify(finalFilter)}`).then(async (resp) => {
      if (resp && resp.messages?.length > 0) {
        if(resp.pagination?.page) {
          if(resp.pagination?.page === 1) setIsInitialLoaded(true);
          let obj = Object.assign(finalFilter, {"page" : resp.pagination?.page});
          filterRef.current = obj
        }
        if(resp.pagination?.totalCount) {
          setTotalCount(resp.pagination?.totalCount)
        }
        if(finalFilter.page  === 1) {
          messagesRef.current = resp.messages?.reverse()
          setMessages(messagesRef.current);
        } else {
          previousScrollHeightRef.current = chatHistoryRef.current?.scrollHeight;
          messagesRef.current = [...resp.messages?.reverse(),...messagesRef.current]
          setMessages(messagesRef.current);
        }
      }
    })
  };

  const onScrollTop = (e) => {
    const bottom = e.target.scrollTop == 0;
    if(bottom  && messages.length < totalCount && isInitialLoaded) {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      insertMessages(currentFilter)

    }
  }


  const handleActiveSpeectMessage = (messageId) => {
    setActiveSpeechMessageId(messageId)
  }


  const handleRegenerate = (message) => {
    handleSend('', message.question, 'regenerate')
  }

  return (
    <div className='summary-search-containar' style={{ backgroundColor : '#fff'}}>
    <div className='d-flex col-sm-12 align-items-start'>
      <MenuBar setMessages={setMessages} chatId={chatId} ref={menuRef} searchType={'privateAi'} defaultSearchFilter={{key : 'chatType' ,value : "Private AI" , type : 'eq'}} shareChats={false}/>
      <div className='col-sm-9 justify-content-center align-items-center chat-bot-main'>
        <div className="chatbot-container">
          {/* for future implementation */}
         {false && <div className='d-flex justify-content-end align-items-center gap-2'>
            <Button>Summarize a case</Button>
          </div>}
          {
            chatId || messages?.length > 0? 
            <> 
            <div className="chatbot-messages" ref={chatHistoryRef} onScroll={onScrollTop}>
              {messages.map((message, index) => (
                <>
                  <div className='d-flex justify-content-end align-items-center' key={'question'+index}>
                    <div className={'message user'}>{message?.question}</div>
                    <div className='icon-style mx-2'>
                      <img src={noProfileImage} alt="" />
                    </div>
                  </div> 
                  <div className='d-flex justify-content-start align-items-start' key={'answer'+index}>
                    <div className='icon-style mx-2 mt-1'>
                      <FontAwesomeIcon
                        className='genderIconAlignment'
                        color='white'
                        icon='comment-dots'
                        data-toggle="tool-tip"
                        title='Send'
                      />
                    </div>
                    {
                      
                      message?.answer && message?.answer !== 'loader' ?
                        <div className='message bot'>
                          <div dangerouslySetInnerHTML={{ __html: message?.type !== 'error' ? marked(message.answer) : message.answer }} ></div>
                          <div className='d-flex justify-content-start align-items-center gap-1 mt-1 message-options-container' style={index === messages.length -1 ? {visibility : 'visible'} : {}}>
                          <span className='message-options'>
                            <ReadAloud text={message.answer} onActivate={(msg) => handleActiveSpeectMessage(msg === 'cancel' ? null : message._id)} isActive={activeSpeechMessageId == message._id}/>
                          </span>
                          <span className='message-options'>
                            <CopyText text={message.answer}/>
                          </span > 
                          <span className='message-options'>
                            <CopyText text={message.answer} copyType={'TextCopy'} iconName={'copy'}/>
                          </span > 
                          <span className='message-options'>
                            <FontAwesomeIcon
                                    icon='share'
                                    className='pl-1' size='lg'
                                    data-toggle="tool-tip" title="Share"
                                    style={{
                                      color :'#2A3650'
                                    }}
                              />
                          </span>
                          {index === messages.length -1 && <span className='message-options'>
                            <FontAwesomeIcon
                              icon='sync-alt'
                              className='pl-1' size='lg'
                              data-toggle="tool-tip" title="Regenerate"
                              style={{
                                color :'#2A3650'
                              }}
                              onClick={() => handleRegenerate(message)}
                              />
                          </span>}
                          </div>
                        </div>
                    :
                      <div className='message bot'>
                        <TypingLoader />
                      </div>
                    }
                    
                  </div>
                  </>
              ))}
            </div>
           
          </> :
          <div className='new-chat'>
            <img src={configImages.dosystemsImg} alt="" />
          </div>
          }
        </div>
        <div className="chat-bot-input-wruapper col-sm-12 p-2 mt-2">
          <div className="chatbot-input col-sm-12 mt-3">
            <input
              ref={inputRef}
              type="text"
              value={input}
              placeholder={'Search Here ...'}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            />
            <button onClick={handleSend} disabled={disableButton}>
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='#fff'
                icon='paper-plane'
                data-toggle="tool-tip"
                title='Send'
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Search;
