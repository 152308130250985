import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams, useLocation } from "react-router";
import { Link, NavLink, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


// config file

const SharedFiles = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [filterQueryParam, SetFilterQueryParam] = useState(null);
  const [displayTable, setDisplayTable] = useState(true);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [patharray, setPathArray] = useState([])
  const [dataLoadType, setDataLoadType] = useState('folders');
  const [folderPermission,setFolderPermission]=useState('')


  const [viewType, setViewType] = useState('grid');

  let location = useLocation()
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Shared With Me");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);




  useEffect(() => {
    if (query.get('searchId') && query.get('searchName')) {
      SetFilterQueryParam({ searchId: query.get('searchId'), searchName: query.get('searchName') })
      getCurrentFolder()
      getparentFolders()
    } else {
      SetFilterQueryParam(null);
      setCurrentFolder(null);
      setPathArray([]);
    }
    setDisplayTable(false);
    setTimeout(() => {
      setDisplayTable(true)
    }, 100);
  }, [location])

  function getparentFolders() {
    fetchMethodRequest('GET', `${apiCalls.sharedFiles}/parentFolders?folderId=${query.get('searchId')}`).then((response) => {
      if(response.parentFolders && response.parentFolders.length > 0) {
        setPathArray(response.parentFolders)
      }
    })
  }

  function getCurrentFolder() {
    fetchMethodRequest('GET', apiCalls.sharedFiles + '/' + query.get('searchId')).then((response) => {
      if (response && response.details) {
        setCurrentFolder(response.details);
        setFolderPermission(response.details?.permissions)
      }
    });
  }





  const permissionsOptions = [
    { label: 'Viewer', value: 'View' },
    { label: 'Commenter', value: 'commenter' },
    { label: 'Editor', value: 'Edit' }
  ];


  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: false,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        displayInSettings: true,
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        controllerName: null,
        fieldType: "redirectable",
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        redirectFilterField: 'parentId',
        fileViewType: 'view',
        redirecTo: apiCalls.sharedFiles,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showIcon: true,

      },

      {
        name: "path",
        fieldName: "path",
        type: "text",
        placeholder: "Path",
        value: "",
        label: "Path",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Path",
        derivedValue: "path=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "path",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: false,
        field: "path",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: false,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "parentFolderId",
        fieldName: "parentFolderId",
        type: "relateAutoComplete",
        placeholder: "Parent Folder",
        value: "",
        label: "Parent Folder",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Parent Folder",
        derivedValue: "parentFolderId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "parentFolderId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1011,
        isClickable: "",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "folders",
        searchApi: "folders",
        textAlign: "center",
        show: false,
        field: "parentFolderId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "fileId",
        fieldName: "fileId",
        type: "relateAutoComplete",
        placeholder: "FileId",
        value: "",
        label: "FileId",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "File",
        derivedValue: "fileId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "fileId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1010,
        isClickable: "",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "files",
        searchApi: "files",
        textAlign: "left",
        show: true,
        field: "fileId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "permissions",
        fieldName: "permissions",
        type: "text",
        placeholder: "Permissions",
        value: "",
        label: "Permissions",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Permissions",
        derivedValue: "permissions=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "permissions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "left",
        show: true,
        field: "permissions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "sharedWith",
        fieldName: "sharedWith",
        type: "relateAutoComplete",
        placeholder: "Shared With",
        value: "",
        label: "SharedWith",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Shared With",
        derivedValue: "sharedWith=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "sharedWith",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: false,
        field: "sharedWith",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Shared By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "left",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
        customWidth : 'col-md-6 col-sm-12 pl-1',
        designIcon : 'user'
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Shared Date",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDisplayModalFormat,
        textAlign: "left",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        customWidth : 'col-md-6 col-sm-12 px-0',
        designIcon : 'calendar'
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: false,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: false,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const getUploadField = () => {
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
        customClass : 'col-sm-12',
      },
      {
        name: "multiFile",
        fieldName: "multiFile",
        type: "fileUpload",
        placeholder: "MultiFile",
        label: "File Upload",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "multiFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "multiFile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: true,
        required: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "fileUpload",
        imagePath: "Files",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
    ];
  };
  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customClass : 'col-sm-12',
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "path",
        fieldName: "path",
        type: "text",
        placeholder: "Path",
        value: "",
        label: "Path",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Path",
        derivedValue: "path=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "path",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "path",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "parentFolderId",
        fieldName: "parentFolderId",
        type: "relateAutoComplete",
        placeholder: "Parent FolderId",
        value: "",
        label: "Parent FolderId",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Parent FolderId",
        derivedValue: "parentFolderId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "parentFolderId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1011,
        isClickable: "",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "folders",
        searchApi: "folders",
        textAlign: "center",
        show: true,
        field: "parentFolderId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "folders_name",
        type: "text",
        placeholder: "folders_name",
        label: "folders_name",
        header: "folders name",
        width: 110,
        parentField: "parentFolderId",
        id: "folders_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "folders_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fileId",
        fieldName: "fileId",
        type: "relateAutoComplete",
        placeholder: "FileId",
        value: "",
        label: "FileId",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "FileId",
        derivedValue: "fileId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "fileId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1010,
        isClickable: "",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "files",
        searchApi: "files",
        textAlign: "center",
        show: true,
        field: "fileId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "files_name",
        type: "text",
        placeholder: "files_name",
        label: "files_name",
        header: "files name",
        width: 110,
        parentField: "fileId",
        id: "files_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "files_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "permissions",
        fieldName: "permissions",
        type: "text",
        placeholder: "Permissions",
        value: "",
        label: "Permissions",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Permissions",
        derivedValue: "permissions=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "permissions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "permissions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sharedWith",
        fieldName: "sharedWith",
        type: "relateAutoComplete",
        placeholder: "SharedWith",
        value: "",
        label: "SharedWith",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "SharedWith",
        derivedValue: "sharedWith=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "sharedWith",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "center",
        show: true,
        field: "sharedWith",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employee_firstName",
        type: "text",
        placeholder: "employee_firstName",
        label: "employee_firstName",
        header: "employee firstName",
        width: 110,
        parentField: "sharedWith",
        id: "employee_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: true,
        field: "employee_firstName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const customDisplayHeader = () => {
    const basePath = 'Shared With Me';
    return (
      <span>
        <a onClick={() => navigate('/sharedFiles')} style={{ cursor: 'pointer' }} className='heading'>{basePath}</a>
        {Array.isArray(patharray) && patharray.map((item, index) => {
          return (
            <span key={index}>
              {' / '}
              <NavLink to={`?searchId=${item.searchId}&searchName=${'parentId'}`} >
                <span className='heading' >{item['name']}</span>
              </NavLink>
            </span>
          );
        })}
      </span>
    );
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.sharedFiles}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {displayTable &&
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={rolePermission && rolePermission == "Edit" && dataLoadType === 'folders' ? true : false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          sample={false}
          actionsTypes={[]}
          settingsRequired={true}

          filterRequired={false}
          gridRequried={true}
          exportToCsv={false}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          // uploderequired={true}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.sharedFiles}
          filterQueryParam={filterQueryParam}
          showHomeScreenData={true}
          shareRequired={true}
        folderPermission={folderPermission}
          customDisplayHeader={customDisplayHeader}
          globalSearch={
            "Folder / File Name"
          }
          displayName="Shared With Me"
          type="SharedFiles"
          query={query}
          routeTo={apiCalls.sharedFiles}
          assingValueToForm={currentFolder ? { path: currentFolder?.folderId?.path?.slice(22), parentFolderId: currentFolder?.folderId._id } : null}
          viewType={{ viewType: viewType, setViewType: setViewType }}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.sharedFiles?.toLowerCase()}
          apiUrl={apiCalls.sharedFiles}
          formUrl={dataLoadType === 'files' ? apiCalls.files : apiCalls.folders}
          dataLoadType={{ dataLoadType: dataLoadType, setDataLoadType: setDataLoadType }}
          uploadDetails={{ getFormFields: getUploadField, formUrl: apiCalls.files, type: 'Files' }}
          selectedId={params.id}
          buttonToolTips = {{add : 'Add Folder'}}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="sharedFiles"
          apiUrl={apiCalls.sharedFiles}
        />
      ) : null}
    </span>
  );
};

export default SharedFiles;
