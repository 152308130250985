import React, { useCallback, useState, useRef ,useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import fetchMethodRequest from '../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../config/configMessages';
import Loader from './Loader';
import JSZip from 'jszip';
import config from '../../../config/config';

const Dropzone = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({ totalFiles: 0, uploadedFiles: 0 });
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const onDrop = useCallback((acceptedFiles) => {
        const hasFolder = acceptedFiles.some(file => file.path && file.path.includes('/'));
        const initialFiles = acceptedFiles.map(file => ({
            name: file.name,
            progress: 0,
            status: 'uploading'
        }));
        setFiles(initialFiles);
        setIsLoaderVisible(true);
        setUploadProgress({ totalFiles: acceptedFiles.length, uploadedFiles: 0 });

        if (hasFolder) {
            handleFolderDrop(acceptedFiles);
        } else {
            handleFilesUpload(acceptedFiles);
        }
    }, []);

    useEffect(()=>{
        if(props.onRef){
            props.onRef({
                setFiles,
                setIsLoaderVisible,
                setUploadProgress,
                updateFileStatus
            })
        }
    },[]);

    const handleFilesUpload = (files) => {
        files.forEach((file, index) => {
            const formData = new FormData();
            formData.append('file', file);
            let url = '';
            if (props?.assingValueToForm?.path && props?.assingValueToForm?.parentFolderId) {
                url = `files?path=${props?.assingValueToForm?.path}&folderId=${props?.assingValueToForm?.parentFolderId}&type=files`;
            } else {
                url = 'files';
            }

            fetchMethodRequest("POST", url, formData, 'upload', true, props.assingValueToForm)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        props.getDataFromServer();
                        showToasterMessage(response.respMessage, 'success');
                        updateFileStatus(index, 100, 'success');
                        setIsDragging(false)
                    } else if (response && response.errorMessage) {
                        props.getDataFromServer();
                        showToasterMessage(response.errorMessage, 'error');
                        updateFileStatus(index, 100, 'error');
                        setIsDragging(false)
                    }
                }).catch(() => {
                    updateFileStatus(index, 100, 'error');
                });
        });
    };

    const handleFolderDrop = useCallback(async (files) => {
        try {
            const zip = new JSZip();
            const filePromises = files.map((file) => {
                const relativePath = file.path.replace(/^\//, '');
                return file.arrayBuffer()
                    .then(fileContent => zip.file(relativePath, fileContent))
                    .catch(error => {
                        console.error('Error processing file:', error);
                        throw error;
                    });
            });

            await Promise.all(filePromises);
            const blob = await zip.generateAsync({ type: "blob" });
            const formData = new FormData();
            formData.append('file', blob, 'uploaded-folder.zip');

            let url = '';
            if (props?.assingValueToForm?.path && props?.assingValueToForm?.parentFolderId) {
                url = `folders/uploadFolder?path=${props?.assingValueToForm?.path}&folderId=${props?.assingValueToForm?.parentFolderId}&type=files`;
            } else {
                url = 'folders/uploadFolder';
            }

            fetchMethodRequest('POST', url, formData)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        props.getDataFromServer();
                        setIsDragging(false);
                        showToasterMessage(response.respMessage, 'success');
                        files.forEach((file, index) => updateFileStatus(index, 100, 'success'));
                    } else if (response && response.errorMessage) {
                        props.getDataFromServer();
                        setIsDragging(false);
                        showToasterMessage(response.errorMessage, 'error');
                        files.forEach((file, index) => updateFileStatus(index, 100, 'error'));
                    }
                })
                .catch((err) => {
                    console.error('Fetch error:', err);
                    showToasterMessage('Upload failed', 'error');
                    files.forEach((file, index) => updateFileStatus(index, 100, 'error'));
                });
        } catch (error) {
            console.error('Error uploading folder:', error);
            showToasterMessage('Upload failed', 'error');
        }
    }, [props.assingValueToForm, props.getDataFromServer]);

    const updateFileStatus = (index, progress, status) => {
        let noOfFiles = 0;
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].progress = progress;
            updatedFiles[index].status = status;
            noOfFiles = updatedFiles.length
            return updatedFiles;
        });
        setUploadProgress((prevProgress) => ({
            ...prevProgress,
            uploadedFiles: prevProgress.uploadedFiles + 1,
        }));
        if(noOfFiles === index +1) {
            setTimeout(() => {
               setIsLoaderVisible(false) 
            }, config.progressBarClosingTime);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
        webkitdirectory: true,
    });

    const renderDataSection = () => {
           if(props.viewType === 'Grid')
            return (
                    <>
                        {props.folders.length === 0 && props.files.length === 0 ? (
                            <h3 className='text-center' style={{ textAlign: 'center' }}>No Data Found</h3>
                        ) : (
                            <>
                                {props.folders.length > 0 && (
                                    <>
                                        <h3 className='d-flex p-1' style={{ marginLeft: '3px' }}>Folders</h3>
                                        {props.renderData(props.folders)}
                                    </>
                                )}
                                {props.files.length > 0 && (
                                    <>
                                        <h3 className='d-flex p-1 mt-3' style={{ marginLeft: '3px' }}>Files</h3>
                                        {props.renderData(props.files)}
                                    </>
                                )}
                            </>
                        )}
                    </>
                );
            else
            return (
                <>{props.renderData()}</>
            )
    };

    const renderDropzone = () => {
        return (
            <div {...getRootProps()} style={{ ...dropzoneStyle, ...(isDragActive ? activeStyle : {}) }} className='dropzone-scroll'>
                <input {...getInputProps()} ref={fileInputRef} multiple />
                <div className='whbgrcolor row ml-lg-0 pr-1 gx-1 pb-3'>
                  {renderDataSection()}          
                </div>
                <div className='water-mark'></div>
                <div className="upload-icon-container">
                    <FontAwesomeIcon
                        icon={isDragActive ? faCloudUploadAlt : null}
                        size="4x"
                        color={isDragActive ? "rgb(13, 110, 253)" : ''}
                        className={`upload-icon ${isDragActive ? 'animate-cloud' : ''}`}
                    />
                </div>
                {isDragActive && (
                    <div className="text-icon-container">
                        <div className="dropLocationStyle">
                            <p style={dropTextStyle}>
                                {props.assingValueToForm?.path
                                    ? configMessages.dragExtenction  + `${props.assingValueToForm.path.split('/').pop()}`
                                    : configMessages.defaultDragMessage}
                            </p>
                        </div>
                    </div>
                )}
                {isLoaderVisible && (
                    <Loader
                        files={files}
                        totalFiles={uploadProgress.totalFiles}
                        uploadedFiles={uploadProgress.uploadedFiles}
                        uploadedFileNames={uploadProgress.uploadedFileNames}
                        onClose={() => setIsLoaderVisible(false)}
                    />
                )}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className='whbgrcolor row ml-lg-0 pr-1 gx-1 pb-3'>
                {renderDataSection()}
            </div>
        );
    };

    return (
        <>
            {(props.displayName === 'Home' || (props.type === 'SharedFiles' && props.query && props.query.size && props.query.size > 0 && props.folderPermission && props.folderPermission === 'Edit'))? renderDropzone() : renderContent()}
        </>
    );
}; 

const dropzoneStyle = {
    borderRadius: '5px',
    padding: '10px',
    textAlign: 'center',
    height: '70vh',
    position: 'relative',
};

const activeStyle = {
    backgroundColor: 'rgba(13, 110, 253, 0.1)',
};


const dropTextStyle = {
    color: '#ffff',
    fontWeight: 'bold',
};

export default Dropzone;
