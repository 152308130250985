import { NavLink } from "react-router-dom";

const HomeNews = () => {
    return (
        <div className="welcome-container">
          <div className="welcome-content">
          <h1 className="welcome-heading">Welcome to Your Legal AI Assistant</h1>
            <p className="welcome-description">
            Manage, summarize, and gain insights from your documents with ease. Ask questions and get the answers you need instantly.
            </p>

            
                <NavLink to={'/myDocuments'}>
                <button className="welcome-button">My Documents</button>
                </NavLink>
          </div>
          <div className="welcome-animations">
            <div className="animation-box"></div>
            <div className="animation-box"></div>
            <div className="animation-box"></div>
          </div>
        </div>
      );

}

export default HomeNews